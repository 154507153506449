import Moment from "moment";
import Mixins from "../../../Mixins.js";

export default {
  name: "EditLoanCollateralSecuritesRestructure",
  components: {},
  mixins: [Mixins],
  data() {
    return {
      property: {
        modal: {
          step4: {
            securities: {
              showModalEditSecurities: false,
            },
          },
        },
        listElement: {
          step4: {
            securities: {
              currentPage: 1,
              rows: 0,
              perPage: 5,
              downloading: false,
              message: "",
            },
          },
        },
        animation: {
          step4: {
            securities: {
              add: {
                isLoading: false,
              },
              edit: {
                isLoading: false,
              },
            },
          },
        },
      },
      dataForm: {
        step4: {
          securities: {
            add: {
              issuedDate: "",
              pilihNomorKontrak: "",
              loanAccountNumber: "",
              collateralNumber: "",
              bankPublisher: "",
              startDate: "",
              collateralValue: 0,
              ownerName: "",
              depositNumber: "",
              timePeriod: 0,
              endDate: "",
              description: "",
              loanCollateralCategoryId: this.fetchAppSession(
                "@vue-session/config-bundle"
              ).VUE_APP_LOAN_COLLATERAL_CATEGORY_SECURITIES,
              loanAccountId: "",
              loanCollateralStatusId: "",
              loanCollateralId: "",
              loanCollateralBindTypeId: "",
            },
            edit: {
              issuedDate: "",
              loanAccountNumber: "",
              collateralNumber: "",
              bankPublisher: "",
              startDate: "",
              collateralValue: 0,
              ownerName: "",
              depositNumber: "",
              timePeriod: 0,
              endDate: "",
              description: "",
              loanCollateralCategoryId: this.fetchAppSession(
                "@vue-session/config-bundle"
              ).VUE_APP_LOAN_COLLATERAL_CATEGORY_SECURITIES,
              loanAccountId: "",
              loanCollateralStatusId: "",
              loanCollateralId: "",
              loanCollateralSecuritiesIdSelected: "",
              loanCollateralBindTypeId: "",
              createdByUserCode: "",
              createdByUserName: "",
              createdDate: "",
              updatedDate: "",
              updatedByUserCode: "",
              updatedByUserName: "",
              authorizedDate: "",
              authorizedByUserCode: "",
              authorizedByUserName: "",
            },
          },
        },
      },
      options: {
        step4: {
          securities: {
            pilihNomorKontrak: [],
            kategoriJaminan: [],
            statusJaminan: [],
            jenisPengikatan: [],
          },
        },
      },
      table: {
        header: {
          step4: {
            securities: [],
          },
        },
        data: {
          step4: {
            securities: [],
          },
        },
      },
    };
  },
  methods: {
    closeModalEditLoanCollateralSecurities() {
      this.property.modal.step4.securities.showModalEditSecurities = false;
      this.resetFormLoanCollateralSecurities("EDIT");
    },
    resetFormLoanCollateralSecurities(type) {
      switch (type) {
        case "ADD":
          this.dataForm.step4.securities.add.collateralNumber = "";
          this.dataForm.step4.securities.add.bankPublisher = "";
          this.dataForm.step4.securities.add.startDate = "";
          this.dataForm.step4.securities.add.collateralValue = 0;
          this.dataForm.step4.securities.add.ownerName = "";
          this.dataForm.step4.securities.add.depositNumber = "";
          this.dataForm.step4.securities.add.timePeriod = 0;
          this.dataForm.step4.securities.add.endDate = "";
          this.dataForm.step4.securities.add.description = "";
          this.dataForm.step4.securities.add.loanCollateralCategoryId = "";
          this.dataForm.step4.securities.add.loanCollateralStatusId = "";
          this.dataForm.step4.securities.add.loanCollateralId = "";
          this.dataForm.step4.securities.add.loanCollateralBindTypeId = "";
          break;

        case "EDIT":
          this.dataForm.step4.securities.edit.loanAccountNumber = "";
          this.dataForm.step4.securities.edit.collateralNumber = "";
          this.dataForm.step4.securities.edit.bankPublisher = "";
          this.dataForm.step4.securities.edit.startDate = "";
          this.dataForm.step4.securities.edit.collateralValue = 0;
          this.dataForm.step4.securities.edit.ownerName = "";
          this.dataForm.step4.securities.edit.depositNumber = "";
          this.dataForm.step4.securities.edit.timePeriod = 0;
          this.dataForm.step4.securities.edit.endDate = "";
          this.dataForm.step4.securities.edit.description = "";
          this.dataForm.step4.securities.edit.loanCollateralCategoryId = "";
          this.dataForm.step4.securities.edit.loanAccountId = "";
          this.dataForm.step4.securities.edit.loanCollateralStatusId = "";
          this.dataForm.step4.securities.edit.loanCollateralId = "";
          this.dataForm.step4.securities.edit.loanCollateralSecuritiesIdSelected =
            "";
          this.dataForm.step4.securities.edit.loanCollateralBindTypeId = "";
          break;

        default:
          return;
          break;
      }
    },
    changeEndDateInLoanCollateralSecurities() {
      if (
        this.dataForm.step4.securities.add.endDate !== "" &&
        this.dataForm.step4.securities.add.startDate !== ""
      ) {
        var a = Moment(this.dataForm.step4.securities.add.endDate);
        var b = Moment(this.dataForm.step4.securities.add.startDate);
        this.dataForm.step4.securities.add.timePeriod = a.diff(b, "months");
      }
    },
    changeTimePeriodInLoanCollateralSecurities() {
      if (this.dataForm.step4.securities.add.startDate !== "") {
        var periode =
          this.dataForm.step4.securities.add.timePeriod === 0 ||
          this.dataForm.step4.securities.add.timePeriod === ""
            ? 1
            : this.dataForm.step4.securities.add.timePeriod;
        var calculate = new Date(
          new Date(this.dataForm.step4.securities.add.startDate).getTime() +
            periode * 31 * 24 * 60 * 60 * 1000
        ).toISOString();
        this.dataForm.step4.securities.add.endDate = calculate;
      }
    },
    changeEndDateInEditLoanCollateralSecurities() {
      if (
        this.dataForm.step4.securities.edit.endDate !== "" &&
        this.dataForm.step4.securities.edit.startDate !== ""
      ) {
        var a = Moment(this.dataForm.step4.securities.edit.endDate);
        var b = Moment(this.dataForm.step4.securities.edit.startDate);
        this.dataForm.step4.securities.edit.timePeriod = a.diff(b, "months");
      }
    },
    changeTimePeriodInEditLoanCollateralSecurities() {
      if (this.dataForm.step4.securities.edit.startDate !== "") {
        var periode =
          this.dataForm.step4.securities.edit.timePeriod === 0 ||
          this.dataForm.step4.securities.edit.timePeriod === ""
            ? 1
            : this.dataForm.step4.securities.edit.timePeriod;
        var calculate = new Date(
          new Date(this.dataForm.step4.securities.edit.startDate).getTime() +
            periode * 31 * 24 * 60 * 60 * 1000
        ).toISOString();
        this.dataForm.step4.securities.edit.endDate = calculate;
      }
    },
    changeOptionSelectContractNumberLoanCollateralSecurities() {
      if (this.dataForm.step4.securities.add.pilihNomorKontrak === "") {
        this.table.data.step4.securities = [];
        this.property.listElement.step4.securities.rows = 0;
        this.dataForm.step4.securities.add.loanAccountNumber = "";
      } else {
        this.mixin_data_options_loan_collateral_securities_loan_account.map(
          (i) => {
            if (
              i.value === this.dataForm.step4.securities.add.pilihNomorKontrak
            ) {
              this.dataForm.step4.securities.add.loanAccountNumber =
                i.loanAccountNumber;
              this.dataForm.step4.securities.add.loanAccountId =
                i.loanAccountId;
              this.getLoanCollateralSecurities();
            }
          }
        );
      }
    },
    async getLoanCollateralSecurities() {
      this.table.data.step4.securities = [];
      this.property.listElement.step4.securities.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collateral-securities",
          payload: {
            collateralNumber: "",
            loanAccountNumber: this.dataForm.step4.securities.add
              .pilihNomorKontrak,
            page: 0,
            size: this.property.listElement.step4.securities.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.step4.securities = resp.data.data.content;
            this.property.listElement.step4.securities.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetLoanCollateralSecurities(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanCollateralSecurities(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.step4.securities.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetLoanCollateralSecurities(resp) {
      this.table.data.step4.securities = [];
      this.property.listElement.step4.securities.rows = 0;
      this.property.listElement.step4.securities.message = resp.data.message;
    },
    handleErrorLoanCollateralSecurities(error) {
      console.log(error.response);
      this.table.data.step4.securities = [];
      this.property.listElement.step4.securities.rows = 0;
      this.property.listElement.step4.securities.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async addLoanCollateralSecurities() {
      const payload = {
        collateralNumber: this.dataForm.step4.securities.add.collateralNumber,
        bankPublisher: this.dataForm.step4.securities.add.bankPublisher,
        startDate: this.dataForm.step4.securities.add.startDate,
        collateralValue: this.dataForm.step4.securities.add.collateralValue,
        ownerName: this.dataForm.step4.securities.add.ownerName,
        depositNumber: this.dataForm.step4.securities.add.depositNumber,
        timePeriod: this.dataForm.step4.securities.add.timePeriod,
        endDate: this.dataForm.step4.securities.add.endDate,
        description: this.dataForm.step4.securities.add.description,
        loanCollateralCategoryId: this.dataForm.step4.securities.add
          .loanCollateralCategoryId,
        loanAccountId: this.dataForm.step4.securities.add.loanAccountId,
        loanCollateralStatusId: this.dataForm.step4.securities.add
          .loanCollateralStatusId,
        loanCollateralBindTypeId: this.dataForm.step4.securities.add
          .loanCollateralBindTypeId,
        issuedDate: this.dataForm.step4.securities.add.issuedDate,
      };
      console.log(JSON.stringify(payload));
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step4.securities.add.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "loan-collateral-securities",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.resetFormLoanCollateralSecurities("ADD");
                    this.getLoanCollateralSecurities();
                  }, 500);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step4.securities.add.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async changePaginationLoanCollateralSecurities(event) {
      this.table.data.step4.securities = [];
      this.property.listElement.step4.securities.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collateral-securities",
          payload: {
            collateralNumber: "",
            loanAccountNumber: this.dataForm.step4.securities.add
              .pilihNomorKontrak,
            page: event - 1,
            size: this.property.listElement.step4.securities.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.step4.securities = resp.data.data.content;
            this.property.listElement.step4.securities.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetLoanCollateralSecurities(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanCollateralSecurities(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.step4.securities.downloading = false;
        }, timeout);
      }
    },
    async getLoanCollateralSecuritiesById(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.resetFormLoanCollateralSecurities("EDIT");
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl:
              "loan-collateral-securities/" + props.row.loanAccountCollateralId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.step4.securities.edit.loanCollateralSecuritiesIdSelected =
              resp.data.data.loanAccountCollateralId;
            this.dataForm.step4.securities.edit.loanAccountNumber =
              resp.data.data.tloanAccount.loanAccountNumber;
            this.dataForm.step4.securities.edit.collateralNumber =
              resp.data.data.collateralNumber;
            this.dataForm.step4.securities.edit.bankPublisher =
              resp.data.data.bankPublisher;
            this.dataForm.step4.securities.edit.startDate =
              resp.data.data.startDate;
            this.dataForm.step4.securities.edit.collateralValue =
              resp.data.data.collateralValue;
            this.dataForm.step4.securities.edit.ownerName =
              resp.data.data.ownerName;
            this.dataForm.step4.securities.edit.depositNumber =
              resp.data.data.depositNumber;
            this.dataForm.step4.securities.edit.timePeriod =
              resp.data.data.timePeriod;
            this.dataForm.step4.securities.edit.endDate =
              resp.data.data.endDate;
            this.dataForm.step4.securities.edit.description =
              resp.data.data.description;
            this.dataForm.step4.securities.edit.loanCollateralCategoryId = resp
              .data.data.rloanCollateralCategory
              ? resp.data.data.rloanCollateralCategory.rloanCollateralCategoryId
              : "";
            this.dataForm.step4.securities.edit.loanAccountId = resp.data.data
              .tloanAccount
              ? resp.data.data.tloanAccount.loanAccountId
              : "";
            this.dataForm.step4.securities.edit.createdByUserCode =
              resp.data.data.createdByUserCode;
            this.dataForm.step4.securities.edit.createdByUserName =
              resp.data.data.createdByUserName;
            this.dataForm.step4.securities.edit.createdDate =
              resp.data.data.createdDate;
            this.dataForm.step4.securities.edit.updatedDate =
              resp.data.data.updatedDate;
            this.dataForm.step4.securities.edit.updatedByUserCode =
              resp.data.data.updatedByUserCode;
            this.dataForm.step4.securities.edit.updatedByUserName =
              resp.data.data.updatedByUserName;
            this.dataForm.step4.securities.edit.authorizedDate =
              resp.data.data.authorizedDate;
            this.dataForm.step4.securities.edit.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.step4.securities.edit.authorizedByUserName =
              resp.data.data.authorizedByUserName;
            this.dataForm.step4.securities.edit.loanCollateralStatusId = resp
              .data.data.rloanCollateralStatus
              ? resp.data.data.rloanCollateralStatus.rloanCollateralStatusId
              : "";
            this.dataForm.step4.securities.edit.loanCollateralBindTypeId = resp
              .data.data.rloanCollateralBindType
              ? resp.data.data.rloanCollateralBindType.collateralBindTypeId
              : "";
            this.dataForm.step4.securities.edit.issuedDate =
              resp.data.data.issuedDate;
            this.property.modal.step4.securities.showModalEditSecurities = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async editLoanCollateralSecurities() {
      const payload = {
        collateralNumber: this.dataForm.step4.securities.edit.collateralNumber,
        bankPublisher: this.dataForm.step4.securities.edit.bankPublisher,
        startDate: this.dataForm.step4.securities.edit.startDate,
        collateralValue: this.dataForm.step4.securities.edit.collateralValue,
        ownerName: this.dataForm.step4.securities.edit.ownerName,
        depositNumber: this.dataForm.step4.securities.edit.depositNumber,
        timePeriod: this.dataForm.step4.securities.edit.timePeriod,
        endDate: this.dataForm.step4.securities.edit.endDate,
        description: this.dataForm.step4.securities.edit.description,
        loanCollateralCategoryId: this.dataForm.step4.securities.edit
          .loanCollateralCategoryId,
        loanAccountId: this.dataForm.step4.securities.edit.loanAccountId,
        loanCollateralStatusId: this.dataForm.step4.securities.edit
          .loanCollateralStatusId,
        loanCollateralId: this.dataForm.step4.securities.edit.loanCollateralId,
        loanCollateralBindTypeId: this.dataForm.step4.securities.edit
          .loanCollateralBindTypeId,
        issuedDate: this.dataForm.step4.securities.edit.issuedDate,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step4.securities.edit.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-collateral-securities/" +
                  this.dataForm.step4.securities.edit
                    .loanCollateralSecuritiesIdSelected,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.property.modal.step4.securities.showModalEditSecurities = false;
                  setTimeout(() => {
                    this.$buefy.toast.open(this.$NotificationUtils.success);
                    setTimeout(() => {
                      this.resetFormLoanCollateralSecurities("EDIT");
                      this.getLoanCollateralSecurities();
                    }, 500);
                  }, 1000);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step4.securities.edit.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async deleteLoanCollateralSecurities(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-collateral-securities/" +
                  props.row.loanAccountCollateralId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getLoanCollateralSecurities();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    getReferenceLoanCollateralCategory() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-category",
          params: {
            collateralCategoryCodes: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_LOAN_COLLATERAL_CATEGORY_SECURITIES,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const value = i.rloanCollateralCategoryId;
              const text = i.collateralCategoryName;
              this.options.step4.securities.kategoriJaminan.push({
                text,
                value,
              });
            });
          }
        });
    },
    getReferenceLoanCollateralStatus() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-status",
          params: {
            groupCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_GROUP_CODE_COLLATERAL_STATUS_01,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const value = i.rloanCollateralStatusId;
              const text = i.collateralStatusName;
              this.options.step4.securities.statusJaminan.push({ text, value });
            });
          }
        });
    },
    getReferenceLoanCollateralBindType() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-bind-type",
          params: {
            collateralBindTypeCodes: `${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_LOAN_COLLATERAL_BIND_TYPE_GADAI
            },${
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_LOAN_COLLATERAL_BIND_TYPE_FEO
            }`,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const value = i.collateralBindTypeId;
              const text = i.collateralBindTypeName;
              this.options.step4.securities.jenisPengikatan.push({
                text,
                value,
              });
            });
          }
        });
    },
  },
  mounted() {
    this.getReferenceLoanCollateralCategory();
    this.getReferenceLoanCollateralStatus();
    this.getReferenceLoanCollateralBindType();
  },
};
