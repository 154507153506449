import Mixins from "../../../Mixins.js";

export default {
  name: "EditLoanCollateralPreciousMetalRestructure",
  components: {},
  mixins: [Mixins],
  data() {
    return {
      property: {
        listElement: {
          loanCollateralPreciousMetal: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        modal: {
          preciousMetal: {
            showModalEditPreciousMetal: false,
          },
        },
        animation: {
          preciousMetal: {
            add: {
              isLoading: false,
            },
            edit: {
              isLoading: false,
            },
          },
        },
      },
      territoryVariableHelper: {
        preciousMetal: {
          add: {
            provinceId: 0,
            cityId: 0,
            districtId: 0,
          },
          edit: {
            provinceId: 0,
            cityId: 0,
            districtId: 0,
          },
        },
      },
      dataForm: {
        loanCollateralPreciousMetal: {
          add: {
            collateralOwnershipTypeId: "",
            pilihNomorKontrak: "",
            loanAccountNumber: "",
            letterNumber: "",
            letterDate: "",
            dueDate: "",
            onbehalfName: "",
            collateralAddress: "",
            postalCode: "",
            description: "",
            quantity: 0,
            stampDate: "",
            stampName: "",
            imageSituationNumber: "",
            bruto: 0,
            netWeight: 0,
            estimateValue: 0,
            recordDate: "",
            realValue: 0,
            villageId: "",
            loanAccountId: "",
            rLoanCollateralStatusId: "",
            insuranced: true,
            rloanCollateralCategoryId: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_LOAN_COLLATERAL_CATEGORY_PRECIOUS_METAL,
            rloanCollateralTypeId: "",
            rloanCollateralBindTypeId: "",
          },
          edit: {
            collateralOwnershipTypeId: "",
            loanAccountCollateralId: "",
            loanAccountNumber: "",
            letterNumber: "",
            letterDate: "",
            dueDate: "",
            onbehalfName: "",
            collateralAddress: "",
            postalCode: "",
            description: "",
            quantity: 0,
            stampDate: "",
            stampName: "",
            imageSituationNumber: "",
            bruto: 0,
            netWeight: 0,
            estimateValue: 0,
            recordDate: "",
            realValue: 0,
            villageId: "",
            loanAccountId: "",
            rLoanCollateralStatusId: "",
            insuranced: true,
            rloanCollateralCategoryId: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_LOAN_COLLATERAL_CATEGORY_PRECIOUS_METAL,
            rloanCollateralTypeId: "",
            rloanCollateralBindTypeId: "",
            createdByUserCode: "",
            createdByUserName: "",
            createdDate: "",
            updatedDate: "",
            updatedByUserCode: "",
            updatedByUserName: "",
            authorizedDate: "",
            authorizedByUserCode: "",
            authorizedByUserName: "",
          },
        },
      },
      options: {
        preciousMetal: {
          pilihNomorKontrak: [],
          loanCollateralStatus: [],
          loanCollateralCategory: [],
          loanCollateralType: [],
          loanCollateralBindType: [],
          legalitasKepemilikan: [],
          add: {
            provinsi: [],
            kabupaten: [],
            kecamatan: [],
            kelurahan: [],
          },
          edit: {
            provinsi: [],
            kabupaten: [],
            kecamatan: [],
            kelurahan: [],
          },
        },
      },
      table: {
        header: {
          loanCollateralPreciousMetal: [],
        },
        data: {
          loanCollateralPreciousMetal: [],
        },
      },
    };
  },
  methods: {
    async getReferenceLoanCollateralCategory() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-category",
          params: {
            collateralCategoryCodes: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_LOAN_COLLATERAL_CATEGORY_PRECIOUS_METAL,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            this.options.preciousMetal.loanCollateralCategory = [
              { value: "", text: "-- Pilih --" },
            ];
            resp.data.data.map((i) => {
              const value = i.rloanCollateralCategoryId;
              const text = i.collateralCategoryName;
              this.options.preciousMetal.loanCollateralCategory.push({
                text,
                value,
              });
            });
          }
        });
    },
    changeSelectProvinceAddLoanCollateralPreciousMetal() {
      this.options.preciousMetal.add.kabupaten = [];
      this.options.preciousMetal.add.kecamatan = [];
      this.options.preciousMetal.add.kelurahan = [];
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/city",
          params: {
            provinceId: this.territoryVariableHelper.preciousMetal.add
              .provinceId,
            cityName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.cityId;
            const text = i.cityName;
            this.options.preciousMetal.add.kabupaten.push({ value, text });
          });
        });
    },
    changeSelectKabupatenAddLoanCollateralPreciousMetal() {
      this.options.preciousMetal.add.kecamatan = [];
      this.options.preciousMetal.add.kelurahan = [];
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/district",
          params: {
            cityId: this.territoryVariableHelper.preciousMetal.add.cityId,
            districtName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.districtId;
            const text = i.districtName;
            this.options.preciousMetal.add.kecamatan.push({ text, value });
          });
        });
    },
    changeSelectKecamatanAddLoanCollateralPreciousMetal() {
      this.options.preciousMetal.add.kelurahan = [];
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/village",
          params: {
            districtId: this.territoryVariableHelper.preciousMetal.add
              .districtId,
            villageName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.villageId;
            const text = i.villageName;
            const postalcode = i.postalcode;
            this.options.preciousMetal.add.kelurahan.push({
              text,
              value,
              postalcode,
            });
          });
        });
    },
    changeSelectProvinceEditLoanCollateralPreciousMetal() {
      this.options.preciousMetal.edit.kabupaten = [];
      this.options.preciousMetal.edit.kecamatan = [];
      this.options.preciousMetal.edit.kelurahan = [];
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/city",
          params: {
            provinceId: this.territoryVariableHelper.preciousMetal.edit
              .provinceId,
            cityName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.cityId;
            const text = i.cityName;
            this.options.preciousMetal.edit.kabupaten.push({ value, text });
          });
        });
    },
    changeSelectKabupatenEditLoanCollateralPreciousMetal() {
      this.options.preciousMetal.edit.kecamatan = [];
      this.options.preciousMetal.edit.kelurahan = [];
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/district",
          params: {
            cityId: this.territoryVariableHelper.preciousMetal.edit.cityId,
            districtName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.districtId;
            const text = i.districtName;
            this.options.preciousMetal.edit.kecamatan.push({ text, value });
          });
        });
    },
    changeSelectKecamatanEditLoanCollateralPreciousMetal() {
      this.options.preciousMetal.edit.kelurahan = [];
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/village",
          params: {
            districtId: this.territoryVariableHelper.preciousMetal.edit
              .districtId,
            villageName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((i) => {
            const value = i.villageId;
            const postalcode = i.postalcode;
            const text = i.villageName;
            this.options.preciousMetal.edit.kelurahan.push({
              text,
              value,
              postalcode,
            });
          });
        });
    },
    getReferenceProvince() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/province",
          params: {
            provinceName: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((i) => {
              const value = i.provinceId;
              const text = i.provinceName;
              this.options.preciousMetal.add.provinsi.push({ text, value });
              this.options.preciousMetal.edit.provinsi.push({ text, value });
            });
          }
        });
    },
    async addLoanCollateralPreciousMetal() {
      const payload = {
        letterNumber: this.dataForm.loanCollateralPreciousMetal.add
          .letterNumber,
        letterDate: this.dataForm.loanCollateralPreciousMetal.add.letterDate,
        dueDate: this.dataForm.loanCollateralPreciousMetal.add.dueDate,
        onbehalfName: this.dataForm.loanCollateralPreciousMetal.add
          .onbehalfName,
        collateralAddress: this.dataForm.loanCollateralPreciousMetal.add
          .collateralAddress,
        postalCode: this.dataForm.loanCollateralPreciousMetal.add.postalCode,
        description: this.dataForm.loanCollateralPreciousMetal.add.description,
        quantity: this.dataForm.loanCollateralPreciousMetal.add.quantity,
        stampDate: this.dataForm.loanCollateralPreciousMetal.add.stampDate,
        stampName: this.dataForm.loanCollateralPreciousMetal.add.stampName,
        imageSituationNumber: this.dataForm.loanCollateralPreciousMetal.add
          .imageSituationNumber,
        bruto: this.dataForm.loanCollateralPreciousMetal.add.bruto,
        netWeight: this.dataForm.loanCollateralPreciousMetal.add.netWeight,
        estimateValue: this.dataForm.loanCollateralPreciousMetal.add
          .estimateValue,
        recordDate: this.dataForm.loanCollateralPreciousMetal.add.recordDate,
        realValue: this.dataForm.loanCollateralPreciousMetal.add.realValue,
        villageId: this.dataForm.loanCollateralPreciousMetal.add.villageId,
        loanAccountId: this.dataForm.loanCollateralPreciousMetal.add
          .loanAccountId,
        rLoanCollateralStatusId: this.dataForm.loanCollateralPreciousMetal.add
          .rLoanCollateralStatusId,
        insuranced: this.dataForm.loanCollateralPreciousMetal.add.insuranced,
        rloanCollateralCategoryId: this.dataForm.loanCollateralPreciousMetal.add
          .rloanCollateralCategoryId,
        rloanCollateralTypeId: this.dataForm.loanCollateralPreciousMetal.add
          .rloanCollateralTypeId,
        rloanCollateralBindTypeId: this.dataForm.loanCollateralPreciousMetal.add
          .rloanCollateralBindTypeId,
        collateralOwnershipTypeId: this.dataForm.loanCollateralPreciousMetal.add
          .collateralOwnershipTypeId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.preciousMetal.add.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "loan-collateral-precious-metal",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.resetFormLoanCollateralPreciousMetal("ADD");
                    this.getLoanCollateralPreciousMetal();
                  }, 500);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.preciousMetal.add.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async deleteLoanCollateralPreciousMetal(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-collateral-precious-metal/" +
                  props.row.loanAccountCollateralId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getLoanCollateralPreciousMetal();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async getLoanCollateralPreciousMetalById(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl:
              "loan-collateral-precious-metal/" +
              props.row.loanAccountCollateralId,
          });
          if (resp.data.code === "SUCCESS") {
            this.resetFormLoanCollateralPreciousMetal("EDIT");
            this.dataForm.loanCollateralPreciousMetal.edit.loanAccountCollateralId =
              resp.data.data.loanAccountCollateralId;
            this.dataForm.loanCollateralPreciousMetal.edit.loanAccountNumber = resp
              .data.data.tloanAccount
              ? resp.data.data.tloanAccount.loanAccountNumber
              : "";
            this.dataForm.loanCollateralPreciousMetal.edit.loanAccountId = resp
              .data.data.tloanAccount
              ? resp.data.data.tloanAccount.loanAccountId
              : "";
            this.dataForm.loanCollateralPreciousMetal.edit.letterNumber =
              resp.data.data.letterNumber;
            this.dataForm.loanCollateralPreciousMetal.edit.letterDate =
              resp.data.data.letterDate;
            this.dataForm.loanCollateralPreciousMetal.edit.dueDate =
              resp.data.data.dueDate;
            this.dataForm.loanCollateralPreciousMetal.edit.onbehalfName =
              resp.data.data.onbehalfName;
            this.dataForm.loanCollateralPreciousMetal.edit.collateralAddress =
              resp.data.data.collateralAddress;
            this.dataForm.loanCollateralPreciousMetal.edit.postalCode =
              resp.data.data.postalCode;
            this.dataForm.loanCollateralPreciousMetal.edit.description =
              resp.data.data.description;
            this.dataForm.loanCollateralPreciousMetal.edit.quantity =
              resp.data.data.quantity;
            this.dataForm.loanCollateralPreciousMetal.edit.stampDate =
              resp.data.data.stampDate;
            this.dataForm.loanCollateralPreciousMetal.edit.stampName =
              resp.data.data.stampName;
            this.dataForm.loanCollateralPreciousMetal.edit.createdByUserCode =
              resp.data.data.createdByUserCode;
            this.dataForm.loanCollateralPreciousMetal.edit.createdByUserName =
              resp.data.data.createdByUserName;
            this.dataForm.loanCollateralPreciousMetal.edit.createdDate =
              resp.data.data.createdDate;
            this.dataForm.loanCollateralPreciousMetal.edit.updatedDate =
              resp.data.data.updatedDate;
            this.dataForm.loanCollateralPreciousMetal.edit.updatedByUserCode =
              resp.data.data.updatedByUserCode;
            this.dataForm.loanCollateralPreciousMetal.edit.updatedByUserName =
              resp.data.data.updatedByUserName;
            this.dataForm.loanCollateralPreciousMetal.edit.authorizedDate =
              resp.data.data.authorizedDate;
            this.dataForm.loanCollateralPreciousMetal.edit.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.loanCollateralPreciousMetal.edit.authorizedByUserName =
              resp.data.data.authorizedByUserName;
            this.dataForm.loanCollateralPreciousMetal.edit.imageSituationNumber =
              resp.data.data.imageSituationNumber;
            this.dataForm.loanCollateralPreciousMetal.edit.bruto =
              resp.data.data.bruto;
            this.dataForm.loanCollateralPreciousMetal.edit.netWeight =
              resp.data.data.netWeight;
            this.dataForm.loanCollateralPreciousMetal.edit.estimateValue =
              resp.data.data.estimateValue;
            this.dataForm.loanCollateralPreciousMetal.edit.recordDate =
              resp.data.data.recordDate;
            this.dataForm.loanCollateralPreciousMetal.edit.realValue =
              resp.data.data.realValue;
            this.dataForm.loanCollateralPreciousMetal.edit.villageId = resp.data
              .data.mvillage
              ? resp.data.data.mvillage.villageId
              : "";
            this.dataForm.loanCollateralPreciousMetal.edit.collateralOwnershipTypeId = resp
              .data.data.rloanCollateralOwnershipType
              ? resp.data.data.rloanCollateralOwnershipType
                  .collateralOwnershipTypeId
              : "";
            this.getAreaVillageByIdInLoanCollateralPreciousMetal();
            this.dataForm.loanCollateralPreciousMetal.edit.rLoanCollateralStatusId = resp
              .data.data.rloanCollateralStatus
              ? resp.data.data.rloanCollateralStatus.rloanCollateralStatusId
              : "";
            this.dataForm.loanCollateralPreciousMetal.edit.insuranced =
              resp.data.data.insuranced;
            this.dataForm.loanCollateralPreciousMetal.edit.rloanCollateralCategoryId = resp
              .data.data.rloanCollateralCategory
              ? resp.data.data.rloanCollateralCategory.rloanCollateralCategoryId
              : "";
            this.dataForm.loanCollateralPreciousMetal.edit.rloanCollateralTypeId = resp
              .data.data.rloanCollateralType
              ? resp.data.data.rloanCollateralType.collateralTypeId
              : "";
            this.dataForm.loanCollateralPreciousMetal.edit.rloanCollateralBindTypeId = resp
              .data.data.rloanCollateralBindType
              ? resp.data.data.rloanCollateralBindType.collateralBindTypeId
              : "";
            this.property.modal.preciousMetal.showModalEditPreciousMetal = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async getAreaVillageByIdInLoanCollateralPreciousMetal() {
      const payload = {
        id: this.dataForm.loanCollateralPreciousMetal.edit.villageId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$store
          .dispatch("GET_MASTER_AREA_VILLAGE_BY_ID", payload)
          .then((resp) => {
            this.territoryVariableHelper.preciousMetal.edit.districtId =
              resp.data.data.mdistrictDto.districtId;
            if (resp.data.code === "SUCCESS") {
              this.territoryVariableHelper.preciousMetal.edit.provinceId = resp
                .data.data.mdistrictDto
                ? resp.data.data.mdistrictDto.mcityDto
                  ? resp.data.data.mdistrictDto.mcityDto.mprovinceDto
                    ? resp.data.data.mdistrictDto.mcityDto.mprovinceDto
                        .provinceId
                    : ""
                  : ""
                : "";
              this.territoryVariableHelper.preciousMetal.edit.cityId = resp.data
                .data.mdistrictDto
                ? resp.data.data.mdistrictDto.mcityDto
                  ? resp.data.data.mdistrictDto.mcityDto.cityId
                  : ""
                : "";
              this.changeSelectProvinceEditLoanCollateralPreciousMetal();
              this.changeSelectKabupatenEditLoanCollateralPreciousMetal();
              this.changeSelectKecamatanEditLoanCollateralPreciousMetal();
            }
          });
      }
    },
    resetFormLoanCollateralPreciousMetal(type) {
      switch (type) {
        case "ADD":
          this.dataForm.loanCollateralPreciousMetal.add.letterNumber = "";
          this.dataForm.loanCollateralPreciousMetal.add.letterDate = "";
          this.dataForm.loanCollateralPreciousMetal.add.dueDate = "";
          this.dataForm.loanCollateralPreciousMetal.add.onbehalfName = "";
          this.dataForm.loanCollateralPreciousMetal.add.collateralAddress = "";
          this.dataForm.loanCollateralPreciousMetal.add.postalCode = "";
          this.dataForm.loanCollateralPreciousMetal.add.description = "";
          this.dataForm.loanCollateralPreciousMetal.add.quantity = 0;
          this.dataForm.loanCollateralPreciousMetal.add.stampDate = "";
          this.dataForm.loanCollateralPreciousMetal.add.stampName = "";
          this.dataForm.loanCollateralPreciousMetal.add.imageSituationNumber =
            "";
          this.dataForm.loanCollateralPreciousMetal.add.bruto = 0;
          this.dataForm.loanCollateralPreciousMetal.add.netWeight = 0;
          this.dataForm.loanCollateralPreciousMetal.add.estimateValue = 0;
          this.dataForm.loanCollateralPreciousMetal.add.recordDate = "";
          this.dataForm.loanCollateralPreciousMetal.add.realValue = 0;
          this.dataForm.loanCollateralPreciousMetal.add.villageId = "";
          this.dataForm.loanCollateralPreciousMetal.add.rLoanCollateralStatusId =
            "";
          this.dataForm.loanCollateralPreciousMetal.add.insuranced = true;
          this.dataForm.loanCollateralPreciousMetal.add.rloanCollateralCategoryId =
            "";
          this.dataForm.loanCollateralPreciousMetal.add.rloanCollateralTypeId =
            "";
          this.dataForm.loanCollateralPreciousMetal.add.rloanCollateralBindTypeId =
            "";
          this.territoryVariableHelper.preciousMetal.add.provinceId = "";
          this.territoryVariableHelper.preciousMetal.add.cityId = "";
          this.territoryVariableHelper.preciousMetal.add.districtId = "";
          this.options.preciousMetal.add.kabupaten = [];
          this.options.preciousMetal.add.kecamatan = [];
          this.options.preciousMetal.add.kelurahan = [];
          break;

        case "EDIT":
          this.dataForm.loanCollateralPreciousMetal.edit.loanAccountCollateralId =
            "";
          this.dataForm.loanCollateralPreciousMetal.edit.loanAccountId = "";
          this.dataForm.loanCollateralPreciousMetal.edit.loanAccountNumber = "";
          this.dataForm.loanCollateralPreciousMetal.edit.letterNumber = "";
          this.dataForm.loanCollateralPreciousMetal.edit.letterDate = "";
          this.dataForm.loanCollateralPreciousMetal.edit.dueDate = "";
          this.dataForm.loanCollateralPreciousMetal.edit.onbehalfName = "";
          this.dataForm.loanCollateralPreciousMetal.edit.collateralAddress = "";
          this.dataForm.loanCollateralPreciousMetal.edit.postalCode = "";
          this.dataForm.loanCollateralPreciousMetal.edit.description = "";
          this.dataForm.loanCollateralPreciousMetal.edit.quantity = 0;
          this.dataForm.loanCollateralPreciousMetal.edit.stampDate = "";
          this.dataForm.loanCollateralPreciousMetal.edit.stampName = "";
          this.dataForm.loanCollateralPreciousMetal.edit.imageSituationNumber =
            "";
          this.dataForm.loanCollateralPreciousMetal.edit.bruto = 0;
          this.dataForm.loanCollateralPreciousMetal.edit.netWeight = 0;
          this.dataForm.loanCollateralPreciousMetal.edit.estimateValue = 0;
          this.dataForm.loanCollateralPreciousMetal.edit.recordDate = "";
          this.dataForm.loanCollateralPreciousMetal.edit.realValue = 0;
          this.dataForm.loanCollateralPreciousMetal.edit.villageId = "";
          this.dataForm.loanCollateralPreciousMetal.edit.rLoanCollateralStatusId =
            "";
          this.dataForm.loanCollateralPreciousMetal.edit.insuranced = true;
          this.dataForm.loanCollateralPreciousMetal.edit.rloanCollateralCategoryId =
            "";
          this.dataForm.loanCollateralPreciousMetal.edit.rloanCollateralTypeId =
            "";
          this.dataForm.loanCollateralPreciousMetal.edit.rloanCollateralBindTypeId =
            "";
          this.territoryVariableHelper.preciousMetal.edit.provinceId = "";
          this.territoryVariableHelper.preciousMetal.edit.cityId = "";
          this.territoryVariableHelper.preciousMetal.edit.districtId = "";
          this.options.preciousMetal.edit.kabupaten = [];
          this.options.preciousMetal.edit.kecamatan = [];
          this.options.preciousMetal.edit.kelurahan = [];
          break;

        default:
          return;
      }
    },
    async getReferenceLoanCollateralType() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-type",
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            this.options.preciousMetal.loanCollateralType = [
              { value: "", text: "-- Pilih --" },
            ];
            resp.data.data.map((i) => {
              if (
                i.loanCollateralCategory.rloanCollateralCategoryId ==
                this.dataForm.loanCollateralPreciousMetal.add
                  .rloanCollateralCategoryId
              ) {
                this.dataForm.loanCollateralPreciousMetal.add.rloanCollateralTypeId =
                  i.collateralTypeId;
                const value = i.collateralTypeId;
                const text = i.collateralTypeName;
                this.options.preciousMetal.loanCollateralType.push({
                  text,
                  value,
                });
              }
            });
          }
        });
    },
    async getReferenceLoanCollateralStatus() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-status",
          params: {
            groupCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_GROUP_CODE_COLLATERAL_STATUS_03,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            this.options.preciousMetal.loanCollateralStatus = [
              { value: "", text: "-- Pilih --" },
            ];
            resp.data.data.map((i) => {
              const value = i.rloanCollateralStatusId;
              const text = i.collateralStatusName;
              this.options.preciousMetal.loanCollateralStatus.push({
                text,
                value,
              });
            });
          }
        });
    },
    async getReferenceLoanCollateralBindType() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-bind-type",
          params: {
            collateralBindTypeCodes: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_LOAN_COLLATERAL_BIND_TYPE_FEO,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            this.options.preciousMetal.loanCollateralBindType = [
              { value: "", text: "-- Pilih --" },
            ];
            resp.data.data.map((i) => {
              const value = i.collateralBindTypeId;
              const text = i.collateralBindTypeName;
              this.options.preciousMetal.loanCollateralBindType.push({
                text,
                value,
              });
            });
          }
        });
    },
    changeOptionSelectContractNumberLoanCollateralPreciousMetal() {
      if (
        this.dataForm.loanCollateralPreciousMetal.add.pilihNomorKontrak === ""
      ) {
        this.dataForm.loanCollateralPreciousMetal.add.loanAccountNumber = "";
        this.table.data.loanCollateralPreciousMetal = [];
        this.property.listElement.loanCollateralPreciousMetal.rows = 0;
      } else {
        this.mixin_data_options_loan_collateral_precious_metal_loan_account.map(
          (i) => {
            if (
              i.value ===
              this.dataForm.loanCollateralPreciousMetal.add.pilihNomorKontrak
            ) {
              this.dataForm.loanCollateralPreciousMetal.add.loanAccountNumber =
                i.loanAccountNumber;
              this.dataForm.loanCollateralPreciousMetal.add.loanAccountId =
                i.loanAccountId;
              this.getLoanCollateralPreciousMetal();
            }
          }
        );
      }
    },
    async getLoanCollateralPreciousMetal() {
      this.table.data.loanCollateralPreciousMetal = [];
      this.property.listElement.loanCollateralPreciousMetal.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collateral-precious-metal",
          payload: {
            letterNumber: "",
            loanAccountNumber: this.dataForm.loanCollateralPreciousMetal.add
              .pilihNomorKontrak,
            page: 0,
            size: this.property.listElement.loanCollateralPreciousMetal.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanCollateralPreciousMetal =
              resp.data.data.content;
            this.property.listElement.loanCollateralPreciousMetal.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetLoanCollateralPreciousMetal(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanCollateralPreciousMetal(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanCollateralPreciousMetal.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetLoanCollateralPreciousMetal(resp) {
      this.table.data.loanCollateralPreciousMetal = [];
      this.property.listElement.loanCollateralPreciousMetal.rows = 0;
      this.property.listElement.loanCollateralPreciousMetal.message =
        resp.data.message;
    },
    handleErrorLoanCollateralPreciousMetal(error) {
      console.log(error.response);
      this.table.data.loanCollateralPreciousMetal = [];
      this.property.listElement.loanCollateralPreciousMetal.rows = 0;
      this.property.listElement.loanCollateralPreciousMetal.currentPage = 1;
      this.property.listElement.loanCollateralPreciousMetal.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationLoanCollateralPreciousMetal(event) {
      this.table.data.loanCollateralPreciousMetal = [];
      this.property.listElement.loanCollateralPreciousMetal.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-collateral-decree",
          payload: {
            collateralNumber: "",
            loanAccountNumber: this.dataForm.loanCollateralPreciousMetal.add
              .pilihNomorKontrak,
            page: event - 1,
            size: this.property.listElement.loanCollateralPreciousMetal.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanCollateralPreciousMetal =
              resp.data.data.content;
            this.property.listElement.loanCollateralPreciousMetal.rows =
              resp.data.data.totalElements;
          } else {
            handleFailedGetLoanCollateralPreciousMetal(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanCollateralPreciousMetal(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanCollateralPreciousMetal.downloading = false;
        }, timeout);
      }
    },
    closeModalEditLoanCollateralPreciousMetal() {
      this.property.modal.preciousMetal.showModalEditPreciousMetal = false;
      this.resetFormLoanCollateralPreciousMetal("EDIT");
    },
    async editLoanCollateralPreciousMetal() {
      const payload = {
        letterNumber: this.dataForm.loanCollateralPreciousMetal.edit
          .letterNumber,
        letterDate: this.dataForm.loanCollateralPreciousMetal.edit.letterDate,
        dueDate: this.dataForm.loanCollateralPreciousMetal.edit.dueDate,
        onbehalfName: this.dataForm.loanCollateralPreciousMetal.edit
          .onbehalfName,
        collateralAddress: this.dataForm.loanCollateralPreciousMetal.edit
          .collateralAddress,
        postalCode: this.dataForm.loanCollateralPreciousMetal.edit.postalCode,
        description: this.dataForm.loanCollateralPreciousMetal.edit.description,
        quantity: this.dataForm.loanCollateralPreciousMetal.edit.quantity,
        stampDate: this.dataForm.loanCollateralPreciousMetal.edit.stampDate,
        stampName: this.dataForm.loanCollateralPreciousMetal.edit.stampName,
        imageSituationNumber: this.dataForm.loanCollateralPreciousMetal.edit
          .imageSituationNumber,
        bruto: this.dataForm.loanCollateralPreciousMetal.edit.bruto,
        netWeight: this.dataForm.loanCollateralPreciousMetal.edit.netWeight,
        estimateValue: this.dataForm.loanCollateralPreciousMetal.edit
          .estimateValue,
        recordDate: this.dataForm.loanCollateralPreciousMetal.edit.recordDate,
        realValue: this.dataForm.loanCollateralPreciousMetal.edit.realValue,
        villageId: this.dataForm.loanCollateralPreciousMetal.edit.villageId,
        loanAccountId: this.dataForm.loanCollateralPreciousMetal.edit
          .loanAccountId,
        rLoanCollateralStatusId: this.dataForm.loanCollateralPreciousMetal.edit
          .rLoanCollateralStatusId,
        insuranced: this.dataForm.loanCollateralPreciousMetal.edit.insuranced,
        rloanCollateralCategoryId: this.dataForm.loanCollateralPreciousMetal
          .edit.rloanCollateralCategoryId,
        rloanCollateralTypeId: this.dataForm.loanCollateralPreciousMetal.edit
          .rloanCollateralTypeId,
        rloanCollateralBindTypeId: this.dataForm.loanCollateralPreciousMetal
          .edit.rloanCollateralBindTypeId,
        collateralOwnershipTypeId: this.dataForm.loanCollateralPreciousMetal
          .edit.collateralOwnershipTypeId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.preciousMetal.edit.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-collateral-precious-metal/" +
                  this.dataForm.loanCollateralPreciousMetal.edit
                    .loanAccountCollateralId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.closeModalEditLoanCollateralPreciousMetal();
                  setTimeout(() => {
                    this.$buefy.toast.open(this.$NotificationUtils.success);
                    setTimeout(() => {
                      this.resetFormLoanCollateralPreciousMetal("EDIT");
                      this.getLoanCollateralPreciousMetal();
                    }, 500);
                  }, 1000);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.messag,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.preciousMetal.edit.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    getReferenceLoanCollateralOwnershipType() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collateral-ownership-type",
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            this.options.preciousMetal.legalitasKepemilikan = [
              { text: "-- Pilih --", value: "" },
            ];
            resp.data.data.map((i) => {
              const value = i.collateralOwnershipTypeId;
              const text = i.collateralOwnershipTypeName;
              this.options.preciousMetal.legalitasKepemilikan.push({
                text,
                value,
              });
            });
          }
        });
    },
  },
  mounted() {
    this.getReferenceLoanCollateralCategory();
    this.getReferenceLoanCollateralStatus();
    this.getReferenceLoanCollateralType();
    this.getReferenceLoanCollateralBindType();
    this.getReferenceProvince();
    this.getReferenceLoanCollateralOwnershipType();
  },
};
