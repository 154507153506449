import Mixins from "../../Mixins.js";
import LoanInstallmentUploadForm from "@/components/page/loan-installment-upload-form";

export default {
  mixins: [Mixins],
  name: "EditLoanInstallmentRestructure",
  components: {
    LoanInstallmentUploadForm,
  },
  data() {
    return {
      property: {
        modal: {
          step3: {
            showModalEditMarginInstallment: false,
          },
        },
        animation: {
          cetakNasabah: {
            isLoading: false,
          },
          step3: {
            processInstallmentRestructure: {
              isLoading: false,
            },
            updateMargin: {
              isLoading: false,
            },
          },
        },
        listElement: {
          step3: {
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        step3: {
          request: {
            billDay: "",
            pilihNomorKontrak: "",
            cifIdName: "",
            officeName: "",
            realitationDate: "",
            timePeriod: "",
            totalMargin: "",
            dueDate: "",
            nominalLoan: "",
            loanAccountId: "",
            loanAccountNumber: "",
            postponeMargin: 0,
            rescheduleMargin: 0,
            unpaid: 0,
            rateMarginTotal: 0,
            principalUnpaid: 0,
            installmentPrincipal: 0,
            flatMargin: 0,
            loanAccountNumberNew: "",
            loanAccountIdProcess: "",
            newTimePeriod: "",
            margin: 0,
            principal: 0,
            loanAkadTypeAcronym: "",
            updateMargin: {
              newMargin: 0,
              loanInstallmentId: "",
              newPrincipal: 0,
            },
          },
          response: {
            billDay: "",
            dueDate: "",
            nominalLoan: 0,
            totalPeriod: 0,
          },
        },
      },
      options: {
        step3: {
          pilihNomorKontrak: [],
        },
      },
      table: {
        data: {
          step3: [],
        },
      },
    };
  },
  methods: {
    async onDownload() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        loanAccountNumber: this.dataForm.step3.request.loanAccountNumber,
        fileType: "pdf",
        name: this.dataForm.step3.request.cifIdName,
      };
      this.property.animation.cetakNasabah.isLoading = true;
      try {
        const response = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/angsuran-nasabah/files",
          payload: payload,
        });
        this.printOutWindow(response.data);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.cetakNasabah.isLoading = false;
      }
    },
    onUploadLoanInstallment() {
      this.$refs.loanInstallmentUploadFormTRef.onOpenModal();
    },
    async processLoanInstallmentGenerateRestructure() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step3.processInstallmentRestructure.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-installment/generate-restructure/" +
                  this.dataForm.step3.request.loanAccountIdProcess,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.dataForm.step3.response.dueDate =
                      resp.data.data.dueDate;
                    this.dataForm.step3.response.nominalLoan =
                      resp.data.data.nominalLoan;
                    this.dataForm.step3.response.totalPeriod =
                      resp.data.data.totalPeriod;
                    this.table.data.step3 = resp.data.data.detailInstallment;
                  }, 500);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step3.processInstallmentRestructure.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async changeOptionSelectContractNumberInstallment() {
      this.resetFormRequestResponseInstallment();
      this.table.data.step3 = [];
      if (this.dataForm.step3.request.pilihNomorKontrak === "") {
        this.property.listElement.step3.message =
          "Pilih Nomor Kontrak Terlebih Dahulu";
      } else {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        } else {
          try {
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "loan",
              reqUrl:
                "transaction-loan/findByLoanAccountNumber/" +
                this.dataForm.step3.request.pilihNomorKontrak,
            });
            if (resp.data.code === "SUCCESS") {
              this.dataForm.step3.request.billDay = resp.data.data.billDay;
              this.dataForm.step3.request.loanAccountIdProcess =
                resp.data.data.loanAccountId;
              this.dataForm.step3.request.newTimePeriod =
                resp.data.data.timePeriod;
              this.dataForm.step3.request.margin =
                resp.data.data.mloan.rloanMarginCalculate
                  .loanMarginCalculateId === "1"
                  ? resp.data.data.rateFlat
                  : resp.data.data.rateEffective;
              this.dataForm.step3.request.createdByUserCode =
                resp.data.data.createdByUserCode;
              this.dataForm.step3.request.createdByUserName =
                resp.data.data.createdByUserName;
              this.dataForm.step3.request.createdDate =
                resp.data.data.createdDate;
              this.dataForm.step3.request.updatedDate =
                resp.data.data.updatedDate;
              this.dataForm.step3.request.updatedByUserCode =
                resp.data.data.updatedByUserCode;
              this.dataForm.step3.request.updatedByUserName =
                resp.data.data.updatedByUserName;
              this.dataForm.step3.request.authorizedDate =
                resp.data.data.authorizedDate;
              this.dataForm.step3.request.authorizedByUserCode =
                resp.data.data.authorizedByUserCode;
              this.dataForm.step3.request.authorizedByUserName =
                resp.data.data.authorizedByUserName;
              this.dataForm.step3.request.loanAkadTypeAcronym =
                resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym;
              this.dataForm.step3.request.principal =
                resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
                resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                  this.fetchAppSession("@vue-session/config-bundle")
                    .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
                  ? resp.data.data.participateBank
                  : resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                    this.fetchAppSession("@vue-session/config-bundle")
                      .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH
                  ? resp.data.data.nominalPurchase
                  : resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                    this.fetchAppSession("@vue-session/config-bundle")
                      .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_QARDH
                  ? resp.data.data.nominalLoan
                  : 0;

              this.property.listElement.step3.downloading = true;
              try {
                const response = await this.$store.dispatch({
                  type: "GET_DATA_FIND_BY",
                  endPoint: "loan",
                  reqUrl:
                    "loan-installment/findByLoanAccountId/" +
                    resp.data.data.loanAccountId,
                });
                if (response.data.code === "SUCCESS") {
                  this.dataForm.step3.response.billDay =
                    response.data.data.billDay;
                  this.dataForm.step3.response.dueDate =
                    response.data.data.dueDate;
                  this.dataForm.step3.response.nominalLoan =
                    response.data.data.nominalLoan;
                  this.dataForm.step3.response.totalPeriod =
                    response.data.data.totalPeriod;
                  this.table.data.step3 = response.data.data.detailInstallment;
                  this.dataForm.step3.request.realitationDate =
                    response.data.data.realitationDate;
                  this.getLoanInstallmentByLoanAccountNumber(response);
                  console.log("BABY");
                } else {
                  this.table.data.step3 = [];
                  this.property.listElement.step3.message =
                    response.data.message;
                  this.getLoanInstallmentByLoanAccountNumberReschedule(resp);
                  console.log("DABABY");
                }
              } catch (error) {
                console.log(error.response);
              } finally {
                this.property.listElement.step3.downloading = false;
              }
            }
          } catch (error) {
            console.log(error);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
            this.property.listElement.step3.message = error.response
              ? `Gagal Mengunduh Data : ${error.response.statusText}`
              : "Terjadi Kesalahan";
          }
        }
      }
    },
    resetFormRequestResponseInstallment() {
      this.dataForm.step3.request.cifIdName = "";
      this.dataForm.step3.request.officeName = "";
      this.dataForm.step3.request.realitationDate = "";
      this.dataForm.step3.request.timePeriod = "";
      this.dataForm.step3.request.totalMargin = "";
      this.dataForm.step3.request.dueDate = "";
      this.dataForm.step3.request.nominalLoan = "";
      this.dataForm.step3.request.loanAccountIdProcess = "";
      this.dataForm.step3.request.loanAccountNumber = "";
      this.dataForm.step3.request.postponeMargin = 0;
      this.dataForm.step3.request.rescheduleMargin = 0;
      this.dataForm.step3.request.rescheduleMargin = 0;
      this.dataForm.step3.request.unpaid = 0;
      this.dataForm.step3.request.rateMarginTotal = 0;
      this.dataForm.step3.request.principalUnpaid = 0;
      this.dataForm.step3.request.installmentPrincipal = 0;
      this.dataForm.step3.request.flatMargin = 0;
      this.dataForm.step3.request.loanAccountNumberNew = 0;
      this.dataForm.step3.request.newTimePeriod = "";
      this.dataForm.step3.response.dueDate = "";
      this.dataForm.step3.response.totalPeriod = 0;
      this.dataForm.step3.response.nominalLoan = 0;
      this.dataForm.step3.request.margin = 0;
      this.dataForm.step3.request.principal = 0;
      this.dataForm.step3.request.loanAkadTypeAcronym = "";
      this.dataForm.step3.request.realitationDate = "";
    },
    async getLoanInstallmentByLoanAccountNumber(response) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl:
            "loan-installment/findByLoanAccountNumber/" +
            response.data.data.contractNumber,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step3.request.cifIdName = resp.data.data.cifIdName;
          this.dataForm.step3.request.officeName = resp.data.data.officeName;
          this.dataForm.step3.request.timePeriod = resp.data.data.timePeriod;
          this.dataForm.step3.request.nominalLoan = resp.data.data.nominalLoan;
          this.dataForm.step3.request.dueDate = resp.data.data.dueDate;
          this.dataForm.step3.request.loanAccountNumber =
            resp.data.data.loanAccountNumber;
          this.dataForm.step3.request.timePeriod = resp.data.data.timePeriod;
          this.dataForm.step3.request.unpaid = resp.data.data.unpaid;
          this.dataForm.step3.request.rateMarginTotal =
            resp.data.data.rateMarginTotal;
          this.dataForm.step3.request.principalUnpaid =
            resp.data.data.principalUnpaid;
          this.dataForm.step3.request.installmentPrincipal =
            resp.data.data.installmentPrincipal;
          this.dataForm.step3.request.flatMargin = resp.data.data.flatMargin;
        }
      } catch (error) {}
    },
    async getLoanInstallmentByLoanAccountNumberReschedule(resp) {
      try {
        const response = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl:
            "loan-installment/findByLoanAccountNumber/" +
            resp.data.data.loanAccountParent.loanAccountNumber,
        });
        if (response.data.code === "SUCCESS") {
          this.dataForm.step3.request.cifIdName = response.data.data.cifIdName;
          this.dataForm.step3.request.officeName =
            response.data.data.officeName;
          this.dataForm.step3.request.timePeriod =
            response.data.data.timePeriod;
          this.dataForm.step3.request.nominalLoan =
            response.data.data.nominalLoan;
          this.dataForm.step3.request.dueDate = response.data.data.dueDate;
          this.dataForm.step3.request.loanAccountNumber =
            response.data.data.loanAccountNumber;
          this.dataForm.step3.request.timePeriod =
            response.data.data.timePeriod;
          this.dataForm.step3.request.unpaid = response.data.data.unpaid;
          this.dataForm.step3.request.rateMarginTotal =
            response.data.data.rateMarginTotal;
          this.dataForm.step3.request.principalUnpaid =
            response.data.data.principalUnpaid;
          this.dataForm.step3.request.installmentPrincipal =
            response.data.data.installmentPrincipal;
          this.dataForm.step3.request.flatMargin =
            response.data.data.flatMargin;
        }
      } catch (error) {}
    },
    showModalUpdateMarginLoanInstallment(props) {
      this.dataForm.step3.request.updateMargin.newMargin = 0;
      this.dataForm.step3.request.updateMargin.newPrincipal = 0;
      this.dataForm.step3.request.updateMargin.loanInstallmentId = "";
      this.property.modal.step3.showModalEditMarginInstallment = true;
      this.dataForm.step3.request.updateMargin.newMargin = props.row.margin;
      this.dataForm.step3.request.updateMargin.newPrincipal =
        props.row.principal;
      this.dataForm.step3.request.updateMargin.loanInstallmentId =
        props.row.loanInstallmentId;
    },
    async editMarginLoanInstallment() {
      const payload = {
        loanInstallmentId: this.dataForm.step3.request.updateMargin
          .loanInstallmentId,
        newMargin: this.dataForm.step3.request.updateMargin.newMargin,
        newPrincipal: this.dataForm.step3.request.updateMargin.newPrincipal,
      };

      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step3.updateMargin.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl: "loan-installment/update-margin",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.closeModalUpdateMarginLoanInstallment();
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.changeOptionSelectContractNumberInstallment();
                  }, 1000);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step3.updateMargin.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    routeToPageAddLoanInstallmentManualPayment(props) {
      this.$router.push(
        `/loan/installment/manual-payment/new?refId=${this.encryptBASE64(
          JSON.stringify({ loanAccountNumber: props.row.loanAccountNumber })
        )}`
      );
    },
    closeModalUpdateMarginLoanInstallment() {
      this.property.modal.step3.showModalEditMarginInstallment = false;
      this.dataForm.step3.request.updateMargin.newMargin = 0;
      this.dataForm.step3.request.updateMargin.newPrincipal = 0;
      this.dataForm.step3.request.updateMargin.loanInstallmentId = "";
    },
  },
};
