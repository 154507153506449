import EditLoanCollateralDecreeRestructure from "./collateral-decree-restructure";
import EditLoanCollateralFixedAssetRestructure from "./collateral-fixed-asset-restructure";
import EditLoanCollateralSecuritiesRestructure from "./collateral-securities-restructure";
import EditLoanCollateralVehicleRestructure from "./collateral-vehicle-restructure";
import EditLoanCollateralPreciousMetalRestructure from "./collateral-precious-metal-restructure";

export default {
  name: "EditLoanCollateralRestructure",
  components: {
    EditLoanCollateralDecreeRestructure,
    EditLoanCollateralFixedAssetRestructure,
    EditLoanCollateralSecuritiesRestructure,
    EditLoanCollateralVehicleRestructure,
    EditLoanCollateralPreciousMetalRestructure,
  },
};
