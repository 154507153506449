import Mixins from "../../Mixins.js";
import Moment from "moment";

export default {
  name: "EditLoanAccountRestructure",
  mixins: [Mixins],
  data() {
    return {
      identity: {
        loanAccountId: "",
      },
      identifier: {
        cifId: "",
      },
      property: {
        animation: {
          step1: {
            addLoanAccountRestructure: {
              isLoading: false,
            },
          },
        },
        listElement: {
          step2: {
            currentPage: 1,
            rows: 0,
            perPage: 5,
            downloading: false,
            message: "LOAN ACCOUNT NOT FOUND",
          },
        },
      },
      dataForm: {
        step1: {
          outstanding: 0,
          outstandingMargin: 0,
          outstandingPeriod: 0,
          loanAccountNumber: "",
          akadNumber: "",
          akadDate: "",
          startDate: "",
          endDate: "",
          timePeriod: 0,
          billDay: 0,
          isAutodebt: false,
          nominalLoan: 0,
          rateMargin: 0,
          rateMarginTotal: 0,
          nominalMargin: 0,
          nominalTotal: 0,
          rateFlat: 0,
          rateEffective: 0,
          nominalInstallment: 0,
          purposeLoan: "",
          isSmsNotification: false,
          isNeedCollateral: false,
          nominalCollateral: 0,
          collateralSharedPercent: 0,
          ujrahDropGroup: 0,
          ujrahDropGroupSub: 0,
          accountSection: 0,
          gracePeriode: 0,
          graceReason: "",
          feeAdmin: 0,
          feeMaterai: 0,
          feeNotaris: 0,
          feeInsuranceLife: 0,
          feeInsuranceLoss: 0,
          feeInsuranceVehicle: 0,
          blockedFund: 0,
          feeTotal: 0,
          collectorId: "",
          participateBank: 0,
          participateCif: 0,
          profitShareBank: 0,
          profitProjection: 0,
          isRelated: false,
          sp3Id: "",
          margin: false,
          loanId: "",
          savingAccountNumberDro: "",
          savingAccountNumberInstallment: "",
          loanCategoryId: "",
          loanCollateralId: "",
          loanGroupId: "",
          loanGroupParentId: "",
          accountOfficerIdHandle: "",
          accountOfficerIdPromo: "",
          loanAccountParentId: "",
          isLineFacility: false,
          cifNumber: 0,
          cifIdName: "",
          cifCategoryName: "",
          officeName: "",
          cifMobilePhoneNumber: 0,
          sp3Date: "",
          sp3Subject: "",
          loanAkadTypeAcronym: "",
          totalParticipate: 0,
          isProfitShareBankPercentage: false,
          profitShareBankProjection: 0,
          totalMargin: 0,
          totalSellingPrice: 0,
          nominalPurchase: 0,
          journalRecord: {
            productCode: "",
            transactionCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN,
            journalRecordDetails: [],
          },
          akadNumberNotaril: "",
          createdByUserCode: "",
          createdByUserName: "",
          createdDate: "",
          updatedDate: "",
          updatedByUserCode: "",
          updatedByUserName: "",
          authorizedDate: "",
          authorizedByUserCode: "",
          authorizedByUserName: "",
        },
        step2: {
          cifNumber: "",
          cifCategoryName: "",
          cifIdName: "",
          officeName: "",
          akadNumber: "",
          akadDate: "",
          startDate: "",
          timePeriod: "",
          dueDate: "",
          accountNumber: "",
          cifMobilePhoneNumber: "",
          margin: false,
          isNeedCollateral: false,
          nominalCollateral: 0,
          feeAdmin: 0,
          feeMaterai: 0,
          feeNotaris: 0,
          feeInsuranceLife: 0,
          feeInsuranceLoss: 0,
          feeInsuranceVehicle: 0,
          blockedFund: 0,
        },
      },
      options: {
        step1: {
          produkPembiayaan: [],
          grupPendanaan: [],
          subLoanGroup: [],
          loanGroupCategory: [],
          collector: [],
          accountOfficer: [],
          loanAkadType: [],
          loanChartOfAccountByLoanId: [],
          loanCollateral: [],
          savingAccountByCifId: [],
          produkPembiayaanByAkad: [],
        },
        step2: {
          produkPembiayaan: [],
          grupPendanaan: [],
          subLoanGroup: [],
          collector: [],
          accountOfficer: [],
        },
      },
      table: {
        data: {
          step2: [],
        },
      },
      show: {
        position: {
          order: 0,
          name: "",
        },
        history: [],
        steps: [
          { name: "step1", position: 1 },
          { name: "step2", position: 2 },
          { name: "step3", position: 3 },
          { name: "step4", position: 4 },
          { name: "step5", position: 5 },
          { name: "step6", position: 6 },
        ],
        form: [
          { name: "step1", active: false },
          { name: "step2", active: false },
          { name: "step3", active: false },
          { name: "step4", active: false },
          { name: "step5", active: false },
          { name: "step6", active: false },
        ],
      },
    };
  },
  methods: {
    next() {
      const findPositions = this.show.steps.find(
        (i) => i.position === this.show.position.order + 1
      );
      this.show.form.map((i) => {
        if (i.name === findPositions.name) {
          i.active = true;
        }
      });
      this.show.form.map((item) => {
        if (item.name === this.show.position.name) {
          item.active = false;
        }
      });
      this.show.position.name = findPositions.name;
      this.show.position.order = findPositions.position;
      this.show.history.push({ name: findPositions.name });
      document.body.scrollTop = 0;
    },
    back() {
      if (this.show.position.order === 1) {
        return;
      } else {
        this.show.form.map((i) => {
          if (i.name === this.show.history[this.show.history.length - 1].name) {
            i.active = false;
          }
        });
        this.show.history.pop();
        this.show.form.map((item) => {
          if (
            item.name === this.show.history[this.show.history.length - 1].name
          ) {
            item.active = true;
          }
        });
        var findingFromStep = this.show.steps.find(
          (i) => i.name === this.show.history[this.show.history.length - 1].name
        );
        this.show.position.name = findingFromStep.name;
        this.show.position.order = findingFromStep.position;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    },
    defaultSetupStepper() {
      const index = this.show.form[0];
      index.active = true;
      const findingPostionByName = this.show.steps.find(
        (item) => item.name === index.name
      );
      this.show.position.name = findingPostionByName.name;
      this.show.position.order = findingPostionByName.position;
      this.show.history.push({ name: findingPostionByName.name });
    },
    getIdentityFromSession() {
      var params = this.$route.params.id;
      var decrypt = this.decryptBASE64(params);
      this.identity.loanAccountId = decrypt;
    },
    async getLoanAccountRestructureById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListLoanAccountRestructure();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "transaction-loan/findById/" + this.identity.loanAccountId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.step1.akadNumberNotaril =
              resp.data.data.akadNumberNotaril;
            this.dataForm.step1.loanAkadTypeAcronym = resp.data.data.mloan
              ? resp.data.data.mloan.rloanAkadType
                ? resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym
                : ""
              : "";
            this.dataForm.step1.createdByUserCode =
              resp.data.data.createdByUserCode;
            this.dataForm.step1.createdByUserName =
              resp.data.data.createdByUserName;
            this.dataForm.step1.createdDate = resp.data.data.createdDate;
            this.dataForm.step1.updatedDate = resp.data.data.updatedDate;
            this.dataForm.step1.updatedByUserCode =
              resp.data.data.updatedByUserCode;
            this.dataForm.step1.updatedByUserName =
              resp.data.data.updatedByUserName;
            this.dataForm.step1.authorizedDate = resp.data.data.authorizedDate;
            this.dataForm.step1.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.step1.authorizedByUserName =
              resp.data.data.authorizedByUserName;
            this.changeSelectTypeAkadLoanAccountRestructure();
            this.identifier.loanAccountId = resp.data.data.loanAccountId;
            this.identifier.loanAccountNumber =
              resp.data.data.loanAccountNumber;
            this.identifier.cifIdName = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifIdName
                : ""
              : "";
            this.dataForm.step1.akadNumber = resp.data.data.akadNumber;
            this.dataForm.step1.loanAccountNumber =
              resp.data.data.loanAccountNumber;
            this.dataForm.step1.akadDate = resp.data.data.akadDate;
            this.dataForm.step1.startDate = resp.data.data.startDate;
            this.dataForm.step1.timePeriod = resp.data.data.timePeriod;
            this.dataForm.step1.outstandingPeriod =
              resp.data.data.outstandingPeriod;
            this.dataForm.step1.endDate = resp.data.data.endDate;
            this.identifier.cifId = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifId
                : ""
              : "";
            this.dataForm.step1.participateBank =
              resp.data.data.participateBank;
            this.dataForm.step1.participateCif = resp.data.data.participateCif;
            this.dataForm.step1.billDay = resp.data.data.billDay;
            this.dataForm.step1.isAutodebt = resp.data.data.isAutodebt;
            this.dataForm.step1.purposeLoan = resp.data.data.purposeLoan;
            this.dataForm.step1.isSmsNotification =
              resp.data.data.isSmsNotification;
            this.dataForm.step1.isNeedCollateral =
              resp.data.data.isNeedCollateral;
            this.dataForm.step1.nominalCollateral =
              resp.data.data.nominalCollateral;
            this.dataForm.step1.collateralSharedPercent =
              resp.data.data.collateralSharedPercent;
            this.dataForm.step1.ujrahDropGroup = resp.data.data.ujrahDropGroup;
            this.dataForm.step1.ujrahDropGroupSub =
              resp.data.data.ujrahDropGroupSub;
            this.dataForm.step1.accountSection = resp.data.data.accountSection;
            this.dataForm.step1.gracePeriode = resp.data.data.gracePeriode;
            this.dataForm.step1.graceReason = resp.data.data.graceReason;
            this.dataForm.step1.feeAdmin = resp.data.data.feeAdmin;
            this.dataForm.step1.feeMaterai = resp.data.data.feeMaterai;
            this.dataForm.step1.feeNotaris = resp.data.data.feeNotaris;
            this.dataForm.step1.feeInsuranceLife =
              resp.data.data.feeInsuranceLife;
            this.dataForm.step1.feeInsuranceLoss =
              resp.data.data.feeInsuranceLoss;
            this.dataForm.step1.feeInsuranceVehicle =
              resp.data.data.feeInsuranceVehicle;
            this.dataForm.step1.blockedFund = resp.data.data.blockedFund;
            this.dataForm.step1.feeTotal = resp.data.data.feeTotal;
            this.dataForm.step1.collectorId = resp.data.data.collectorId
              ? resp.data.data.collectorId.userId
              : "";
            this.dataForm.step1.profitShareBank =
              resp.data.data.profitShareBank;
            this.dataForm.step1.profitShareBankProjection =
              resp.data.data.profitShareBank;
            this.dataForm.step1.rateFlat = resp.data.data.rateFlat;
            this.dataForm.step1.rateEffective = resp.data.data.rateEffective;
            this.dataForm.step1.margin =
              resp.data.data.mloan.rloanMarginCalculate
                .loanMarginCalculateId === "1"
                ? true
                : false;
            this.dataForm.step1.nominalLoan = resp.data.data.nominalLoan;
            this.dataForm.step1.profitProjection =
              resp.data.data.profitProjection;
            this.dataForm.step1.outstanding = resp.data.data.outstanding;
            this.dataForm.step1.outstandingMargin =
              resp.data.data.outstandingMargin;
            this.dataForm.step1.nominalInstallment =
              resp.data.data.nominalInstallment;
            this.dataForm.step1.isRelated = resp.data.data.isRelated;
            this.dataForm.step1.sp3Id = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.sp3Id
              : "";
            this.dataForm.step1.loanId = resp.data.data.mloan
              ? resp.data.data.mloan.loanId
              : "";
            this.dataForm.step1.savingAccountNumberDro = resp.data.data
              .tsavingAccountDroping
              ? resp.data.data.tsavingAccountDroping.accountNumber
              : "";
            this.dataForm.step1.savingAccountNumberInstallment = resp.data.data
              .tsavingAccountInstallment
              ? resp.data.data.tsavingAccountInstallment.accountNumber
              : "";
            this.dataForm.step1.loanCategoryId = resp.data.data
              .rloanGroupCategory
              ? resp.data.data.rloanGroupCategory.loanGroupCategoryId
              : "";
            this.dataForm.step1.loanCollateralId = resp.data.data
              .mloanCollateral
              ? resp.data.data.mloanCollateral.loanCollateralId
              : "";
            this.dataForm.step1.loanGroupParentId = resp.data.data.mloanGroup
              ? resp.data.data.mloanGroup.loanGroupParentId
                ? resp.data.data.mloanGroup.loanGroupParentId.loanGroupId
                : ""
              : "";
            this.dataForm.step1.loanGroupId = resp.data.data.mloanGroup
              ? resp.data.data.mloanGroup.loanGroupId
              : "";
            this.changeLoanGroupLoanAccount(this.dataForm.step1.loanGroupId);
            this.dataForm.step1.accountOfficerIdHandle = resp.data.data
              .maccountOfficerHandle
              ? resp.data.data.maccountOfficerHandle.userId
              : "";
            this.dataForm.step1.accountOfficerIdPromo = resp.data.data
              .maccountOfficerPromo
              ? resp.data.data.maccountOfficerPromo.userId
              : "";
            this.dataForm.step1.isLineFacility = resp.data.data.isLineFacility;
            this.dataForm.step1.cifNumber = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifNumber
                : ""
              : "";
            this.dataForm.step1.cifIdName = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifIdName
                : ""
              : "";
            this.dataForm.step1.cifCategoryName = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.rcifCategoryByCifCategoryId
                  ? resp.data.data.tloanSp3.mcif.rcifCategoryByCifCategoryId
                      .cifCategoryName
                  : ""
                : ""
              : "";
            this.dataForm.step1.officeName = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.moffice
                  ? resp.data.data.tloanSp3.mcif.moffice.officeName
                  : ""
                : ""
              : "";
            this.dataForm.step1.cifMobilePhoneNumber = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifMobilePhoneNumber
                : ""
              : "";
            this.dataForm.step1.sp3Date = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.sp3Date
              : "";
            this.dataForm.step1.sp3Subject = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.sp3Subject
              : "";
            this.dataForm.step1.cifMobilePhoneNumber = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifMobilePhoneNumber
                : ""
              : "";
            this.dataForm.step1.nominalPurchase =
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
                ? resp.data.data.nominalPurchase
                : 0;
            if (
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
            ) {
              this.dataForm.step1.rateMargin = resp.data.data.rateFlat;
              this.calculateRateLoanAccountRestruct("get_by_id");
            }
            if (
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
            ) {
              this.dataForm.step1.totalParticipate =
                this.dataForm.step1.participateBank +
                this.dataForm.step1.participateCif;
            }
            this.getRefferenceLoanChartOfAccountByLoanIdLoanAccountRestruct(
              resp.data.data.journalRecord
            );
            this.getSavingAccountByCifId();
            this.$store.dispatch(
              "SET_IDENTIFIER_LOAN_ACCOUNT_ID",
              resp.data.data.loanAccountId
            );
            this.MIXINS_METHOD_getRefferenceLoanAccount();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListLoanAccountRestructure(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListLoanAccountRestructure(),
          });
        }
      }
    },
    routeToPageListLoanAccountRestructure() {
      this.$router.push("/loan/loan-account-restructure");
    },
    changeSelectTypeAkadLoanAccountRestructure() {
      this.resetFormNominalPembiayaanLoanAccountRestructure();
      const filterAkad = this.options.step1.produkPembiayaan.filter(
        (index) =>
          index.loanAkadTypeAcronym === this.dataForm.step1.loanAkadTypeAcronym
      );
      this.dataForm.step1.loanId = "";
      this.options.step1.produkPembiayaanByAkad = [
        { value: "", text: "-- Pilih --" },
        ...filterAkad,
      ];
    },
    resetFormNominalPembiayaanLoanAccountRestructure() {
      this.dataForm.step1.participateBank = 0;
      this.dataForm.step1.participateCif = 0;
      this.dataForm.step1.rateFlat = 0;
      this.dataForm.step1.rateEffective = 0;
      this.dataForm.step1.profitShareBank = 0;
      this.dataForm.step1.nominalPurchase = 0;
      this.dataForm.step1.nominalLoan = 0;
      this.dataForm.step1.nominalInstallment = 0;
      this.dataForm.step1.rateMargin = 0;
      this.dataForm.step1.totalMargin = 0;
      this.dataForm.step1.totalSellingPrice = 0;
      this.dataForm.step1.nominalMargin = 0;
      this.dataForm.step1.totalParticipate = 0;
      this.dataForm.step1.profitProjection = 0;
      this.dataForm.step1.profitShareBankProjection = 0;
      this.dataForm.step1.isProfitShareBankPercentage = false;
    },
    calculateRateLoanAccountRestruct(triggerby) {
      this.dataForm.step1.rateFlat = this.dataForm.step1.rateMargin;
      if (triggerby === "manual_input") {
        if (
          this.dataForm.step1.margin === false &&
          this.dataForm.step1.nominalPurchase &&
          this.dataForm.step1.rateMargin
        ) {
          const payload = {
            rateFlat: this.dataForm.step1.rateMargin,
            principal: this.dataForm.step1.nominalPurchase,
          };
          this.convertToEffectiveLoanAccountRestruct(
            payload.rateFlat,
            payload.principal
          );
        }
      }
      this.dataForm.step1.totalMargin = this.dataForm.step1.timePeriod
        ? (this.dataForm.step1.rateMargin / 12) * this.dataForm.step1.timePeriod
        : 0;
      this.dataForm.step1.nominalMargin = this.dataForm.step1.totalMargin
        ? (this.dataForm.step1.totalMargin *
            this.dataForm.step1.nominalPurchase) /
          100
        : 0;
      this.dataForm.step1.totalSellingPrice = this.dataForm.step1.nominalMargin
        ? this.dataForm.step1.nominalMargin +
          this.dataForm.step1.nominalPurchase
        : 0;
      this.dataForm.step1.nominalLoan = this.dataForm.step1.totalSellingPrice;
      this.dataForm.step1.nominalInstallment =
        this.dataForm.step1.nominalLoan / this.dataForm.step1.timePeriod;
    },
    async convertToEffectiveLoanAccountRestruct(rateFlat, principal) {
      const payload = {
        timePeriod: this.dataForm.step1.timePeriod,
        rateFlat: rateFlat,
        principal: principal,
      };

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/convert-flat-to-efective",
          payload: payload,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step1.rateEffective = resp.data.data;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async getRefferenceLoanChartOfAccountByLoanIdLoanAccountRestruct(
      currentJournalRecord
    ) {
      try {
        this.dataForm.step1.journalRecord.journalRecordDetails = [];
        const resp = await this.$store.dispatch({
          endPoint: "loan",
          type: "GET_DATA_FIND_BY",
          reqUrl: `loan-chart-of-account/loan-transaction/${
            this.dataForm.step1.loanId
          }/${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN_ID
          }`,
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          Promise.all(
            resp.data.data.map((i, index) => {
              console.log(index, i);
              const productLedgerId = i.loanChartOfAccountId;
              const chartOfAccountId = i.chartOfAccount
                ? i.chartOfAccount.chartOfAccountId
                : "";
              const label = i.productLedger
                ? i.productLedger.productLedgerName
                : "";
              const positionMutation = i.mutationPosition;
              const labelInputComponent = i.chartOfAccount
                ? i.chartOfAccount.description
                : "";
              const pairPositionMutation = i.pairMutationPosition;
              const pairChartOfAccountId = i.pairChartOfAccount
                ? i.pairChartOfAccount.chartOfAccountId
                : "";
              const chartOfAccountCode = i.chartOfAccount
                ? i.chartOfAccount.chartOfAccountCode
                : "";
              const pairChartOfAccountCode = i.pairChartOfAccount
                ? i.pairChartOfAccount.pairChartOfAccountCode
                : "";
              const pairType = i.pairType;
              const amount = 0;
              this.dataForm.step1.journalRecord.journalRecordDetails.push({
                productLedgerId,
                chartOfAccountId,
                pairChartOfAccountId,
                labelInputComponent,
                label,
                positionMutation,
                pairPositionMutation,
                chartOfAccountCode,
                pairChartOfAccountCode,
                pairType,
                amount,
                index: index,
              });
            })
          ).then(() => {
            if (currentJournalRecord) {
              this.insertJournalRecordLoanAccountRestruct(currentJournalRecord);
            }
          });
        }
      } catch (error) {}
    },
    insertJournalRecordLoanAccountRestruct(currentJournalRecord) {
      this.dataForm.step1.journalRecord.journalRecordDetails.map((index) => {
        currentJournalRecord.journalRecordDetails.map((i) => {
          if (i.chartOfAccountId === index.chartOfAccountId) {
            index.amount = i.amount;
          }
        });
      });
    },
    async getSavingAccountByCifId() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListLoanAccountRestructure();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "saving-account/cif-id/" + this.identifier.cifId,
          payload: {
            isCrossOffice: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.mcif.cifIdName} - ${index.accountNumber} - ${index.msaving.savingName}`;
            const value = index.accountNumber;
            this.options.step1.savingAccountByCifId.push({ text, value });
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan !`,
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    changeProdukPembiayaanLoanAccountRestruct() {
      this.getLoanChartOfAccountByLoanIdLoanAccountRestruct();
      this.options.step1.produkPembiayaan.map((i) => {
        if (i.value === this.dataForm.step1.loanId) {
          this.dataForm.step1.journalRecord.productCode = i.loanCode;
          console.log(i);
          switch (i.marginCalculate) {
            case "1":
              this.dataForm.step1.margin = true;
              this.dataForm.step1.rateEffective = 0;
              break;
            case "2":
              this.dataForm.step1.margin = false;
              this.dataForm.step1.rateFlat = 0;
              break;
            default:
              return;
              break;
          }
        }
      });
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
      ) {
        this.calculateRateLoanAccountRestruct("manual_input");
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
      ) {
        this.calculateRateEffectiveAnnuallyLoanAccountRestruct();
        this.calculateProyeksiAngsuranLoanAccountRestruct();
      }
    },
    async getLoanChartOfAccountByLoanIdLoanAccountRestruct() {
      try {
        this.dataForm.step1.journalRecord.journalRecordDetails = [];
        const resp = await this.$store.dispatch({
          endPoint: "loan",
          type: "GET_DATA_FIND_BY",
          reqUrl: `loan-chart-of-account/loan-transaction/${
            this.dataForm.step1.loanId
          }/${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN_ID
          }`,
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          resp.data.data.map((i, index) => {
            console.log(index, i);
            const productLedgerId = i.loanChartOfAccountId;
            const chartOfAccountId = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountId
              : "";
            const label = i.productLedger
              ? i.productLedger.productLedgerName
              : "";
            const positionMutation = i.mutationPosition;
            const labelInputComponent = i.chartOfAccount
              ? i.chartOfAccount.description
              : "";
            const pairPositionMutation = i.pairMutationPosition;
            const pairChartOfAccountId = i.pairChartOfAccount
              ? i.pairChartOfAccount.chartOfAccountId
              : "";
            const chartOfAccountCode = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountCode
              : "";
            const pairChartOfAccountCode = i.pairChartOfAccount
              ? i.pairChartOfAccount.pairChartOfAccountCode
              : "";
            const pairType = i.pairType;
            const amount = 0;
            this.dataForm.step1.journalRecord.journalRecordDetails.push({
              productLedgerId,
              chartOfAccountId,
              pairChartOfAccountId,
              label,
              positionMutation,
              labelInputComponent,
              pairPositionMutation,
              chartOfAccountCode,
              pairChartOfAccountCode,
              pairType,
              amount,
              index: index,
            });
          });
        }
      } catch (error) {}
    },
    calculateProyeksiAngsuranLoanAccountRestruct() {
      if (
        this.dataForm.step1.nominalLoan !== 0 &&
        this.dataForm.step1.timePeriod !== 0
      ) {
        this.dataForm.step1.nominalInstallment =
          this.dataForm.step1.nominalLoan / this.dataForm.step1.timePeriod;
        return;
      }
      this.dataForm.step1.nominalInstallment = 0;
    },
    changeTipeBagiHasilBankLoanAccountRestruct() {
      switch (this.dataForm.step1.isProfitShareBankPercentage) {
        case true:
          if (
            this.dataForm.step1.profitShareBank !== 0 &&
            this.dataForm.step1.profitProjection !== 0
          ) {
            this.dataForm.step1.profitShareBank =
              (this.dataForm.step1.profitShareBank /
                this.dataForm.step1.profitProjection) *
              100;
            this.dataForm.step1.profitShareBankProjection =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step1.profitShareBank = 0;
          this.dataForm.step1.profitShareBankProjection = 0;
          break;

        case false:
          if (
            this.dataForm.step1.profitShareBank !== 0 &&
            this.dataForm.step1.profitProjection !== 0
          ) {
            this.dataForm.step1.profitShareBankProjection =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            this.dataForm.step1.profitShareBank =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step1.profitShareBank = 0;
          this.dataForm.step1.profitShareBankProjection = 0;
          break;

        default:
          break;
      }
    },
    changeProfitShareBankPercentLoanAccountRestruct() {
      switch (this.dataForm.step1.isProfitShareBankPercentage) {
        case true:
          this.dataForm.step1.profitShareBankProjection =
            (this.dataForm.step1.profitProjection *
              this.dataForm.step1.profitShareBank) /
            100;

          break;
        case false:
          this.dataForm.step1.profitShareBankProjection = this.dataForm.step1.profitShareBank;
          break;

        default:
          break;
      }
      this.calculateRateEffectiveAnnuallyLoanAccountRestruct();
      this.dataForm.step1.nominalLoan = this.dataForm.step1.participateBank;
      this.calculateProyeksiAngsuranLoanAccountRestruct();
    },
    async changeLoanGroupLoanAccount(loanGroupId) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          reqUrl: "m-loan-group/sub",
          endPoint: "loan",
          payload: {
            groupId: this.dataForm.step1.loanGroupParentId,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.step1.subLoanGroup = [];
          this.dataForm.step1.loanGroupId = loanGroupId
            ? this.dataForm.step1.loanGroupId
            : "";
          resp.data.data.map((data) => {
            const text = `${data.groupCode} - ${data.groupName}`;
            const value = data.loanGroupId;
            this.options.step1.subLoanGroup.push({ text, value });
          });
        }
      } catch (error) {}
    },
    calculateRateEffectiveAnnuallyLoanAccountRestruct() {
      if (
        this.dataForm.step1.profitShareBankProjection === 0 ||
        this.dataForm.step1.participateBank === 0 ||
        this.dataForm.step1.timePeriod === 0
      ) {
        this.dataForm.step1.rateEffective = 0;
        this.dataForm.step1.rateFlat = 0;
        return;
      }
      const calculateRate =
        (this.dataForm.step1.profitShareBankProjection /
          this.dataForm.step1.participateBank) *
        100;
      if (this.dataForm.step1.margin === false) {
        const payload = {
          rateFlat: (calculateRate / this.dataForm.step1.timePeriod) * 12,
          principal: this.dataForm.step1.participateBank,
        };
        this.convertToEffectiveLoanAccountRestruct(
          payload.rateFlat,
          payload.principal
        );
      }
      this.dataForm.step1.rateFlat =
        (calculateRate / this.dataForm.step1.timePeriod) * 12;
    },
    changeProfitProjectionLoanAccountRestruct() {
      if (this.dataForm.step1.isProfitShareBankPercentage === true) {
        this.changeProfitShareBankPercentLoanAccountRestruct();
        this.dataForm.step1.nominalLoan = this.dataForm.step1.participateBank;
        this.calculateProyeksiAngsuranLoanAccountRestruct();
      }
      this.calculateRateEffectiveAnnuallyLoanAccountRestruct();
    },
    calculateParticipateLoanAccountRestruct(type) {
      this.dataForm.step1.totalParticipate =
        this.dataForm.step1.participateBank +
        this.dataForm.step1.participateCif;
      this.dataForm.step1.nominalLoan = this.dataForm.step1.participateBank;

      if (type === "WITH_CALCULATE_RATE") {
        this.calculateRateEffectiveAnnuallyLoanAccountRestruct();
        this.calculateProyeksiAngsuranLoanAccountRestruct();
      }
    },
    changeNominalMarginLoanAccountRestruct() {
      if (
        this.dataForm.step1.nominalMargin &&
        this.dataForm.step1.nominalPurchase &&
        this.dataForm.step1.timePeriod
      ) {
        this.dataForm.step1.totalMargin =
          (this.dataForm.step1.nominalMargin /
            this.dataForm.step1.nominalPurchase) *
          100;
        this.dataForm.step1.rateMargin =
          (this.dataForm.step1.totalMargin / this.dataForm.step1.timePeriod) *
          12;
      }
      this.calculateRateLoanAccountRestruct("manual_input");
    },
    changeStartDateLoanAccountResctruct() {
      if (this.dataForm.step1.timePeriod) {
        this.changeTimePeriodLoanAccountRestruct();
      }
    },
    changeTimePeriodLoanAccountRestruct() {
      if (this.dataForm.step1.startDate !== "") {
        var periode =
          this.dataForm.step1.timePeriod === 0 ||
          this.dataForm.step1.timePeriod === ""
            ? 1
            : this.dataForm.step1.timePeriod;
        var calculate = Moment()
          .add(`${periode}`, "months")
          .format();
        this.dataForm.step1.endDate = calculate;
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
      ) {
        this.calculateRateLoanAccountRestruct("manual_input");
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
      ) {
        this.calculateRateEffectiveAnnuallyLoanAccountRestruct();
        this.calculateProyeksiAngsuranLoanAccountRestruct();
      }
    },
    changeEndDateLoanAccountRestruct() {
      if (
        this.dataForm.step1.endDate !== "" &&
        this.dataForm.step1.startDate !== ""
      ) {
        var a = Moment(this.dataForm.step1.endDate);
        var b = Moment(this.dataForm.step1.startDate);
        this.dataForm.step1.timePeriod = a.diff(b, "months");
        if (
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
        ) {
          this.calculateRateLoanAccountRestruct("manual_input");
        }
        if (
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
        ) {
          this.calculateRateEffectiveAnnuallyLoanAccountRestruct();
          this.calculateProyeksiAngsuranLoanAccountRestruct();
        }
      }
    },
    getReferenceLoan() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "loan/simple-list",
          params: {
            loanName: "",
            loanCode: "",
            page: 0,
          },
        })
        .then((resp) => {
          Promise.all(
            resp.data.data.content.map((index) => {
              const text = `${index.loanName} - ${index.loanCode}`;
              const value = index.loanId;
              const marginCalculate = index.loanMarginCalculateCode;
              const loanAkadTypeAcronym = index.loanAkadTypeAcronym;
              const loanCode = index.loanCode;
              this.options.step1.produkPembiayaan.push({
                text,
                value,
                marginCalculate,
                loanAkadTypeAcronym,
                loanCode,
              });
            })
          ).then(() => {
            this.getLoanAccountRestructureById();
          });
        });
    },
    calculateFeeLoanAccount() {
      this.dataForm.step1.feeTotal =
        (!this.dataForm.step1.feeAdmin ? 0 : this.dataForm.step1.feeAdmin) +
        (!this.dataForm.step1.feeMaterai ? 0 : this.dataForm.step1.feeMaterai) +
        (!this.dataForm.step1.feeNotaris ? 0 : this.dataForm.step1.feeNotaris) +
        (!this.dataForm.step1.feeInsuranceLife
          ? 0
          : this.dataForm.step1.feeInsuranceLife) +
        (!this.dataForm.step1.feeInsuranceLoss
          ? 0
          : this.dataForm.step1.feeInsuranceLoss) +
        (!this.dataForm.step1.feeInsuranceVehicle
          ? 0
          : this.dataForm.step1.feeInsuranceVehicle);
    },
    getReferenceLoanGroupCategory() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-group-category",
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const text = i.loanGroupCategoryName;
              const value = i.loanGroupCategoryId;
              this.options.step1.loanGroupCategory.push({ text, value });
            });
          } else {
          }
        });
    },
    getReferenceLoanGroup() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "m-loan-group",
          params: {
            groupCode: "",
            groupName: "",
            accountNumber: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((i) => {
              const text = `${i.groupCode} - ${i.groupName}`;
              const value = i.loanGroupId;
              this.options.step1.grupPendanaan.push({ text, value });
            });
          }
        });
    },
    getReferenceCollector() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
          url: "v2/user/active-user",
          params: {
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const text = `${index.profileSurename} - ${index.userNik}`;
            const value = index.userId;
            this.options.step1.collector.push({ text, value });
          });
        });
    },
    getRefferenceLoanCollateral() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "loan-collateral",
          params: {
            collateralTypeId: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            console.log(resp);
            resp.data.data.content.map((index) => {
              const text = index.description;
              const value = index.loanCollateralId;
              this.options.step1.loanCollateral.push({
                text,
                value,
              });
            });
          }
        });
    },
    getReferenceAccountOfficer() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
          url: "v2/user/active-user",
          params: {
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const text = `${index.profileSurename} - ${index.userNik}`;
            const value = index.userId;
            this.options.step1.accountOfficer.push({ text, value });
          });
        });
    },
    async getReferenceLoanAkadType() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "reference/loan-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanAkadTypeName;
            const value = index.loanAkadTypeAcronym;
            this.options.step1.loanAkadType.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error.response);
      }
    },
  },
  mounted() {
    this.defaultSetupStepper();
    this.getIdentityFromSession();
    this.getReferenceLoanAkadType();
    this.getReferenceLoan();
    this.getReferenceLoanGroup();
    this.getReferenceLoanGroupCategory();
    this.getReferenceCollector();
    this.getRefferenceLoanCollateral();
    this.getReferenceAccountOfficer();
  },
};
