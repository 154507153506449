import EditLoanAccountRestructure from "./loan-account-restructure";
import EditLoanBiCodeRestructure from "./loan-bi-code-restructure";
import EditLoanCollateralRestructure from "./loan-collateral-restructure";
import EditLoanDocumentChecklistRestructure from "./loan-document-checklist-restructure";
import EditLoanInstallmentRestructure from "./loan-installment-restructure";
import Mixins from "../Mixins.js";

export default {
  mixins: [Mixins],
  name: "EditTLoanAccountRestructure",
  components: {
    EditLoanAccountRestructure,
    EditLoanBiCodeRestructure,
    EditLoanCollateralRestructure,
    EditLoanDocumentChecklistRestructure,
    EditLoanInstallmentRestructure,
  },
  destroyed() {
    this.$store.dispatch("CLEAR_OPTIONS_LOAN_ACCOUT");
  },
  methods: {
    centralBackToList() {
      this.$router.push("/loan/loan-account-restructure");
    },
  },
};
